<template>
  <div>
   <div class="mb-4">
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Image</span>
   </div>

    <file-pond
        name="test"
        ref="pond"
        label-idle="Drop files here..."
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
        instant-upload="false"
        :files="image_preview"
        @addfile="handleFileAdd" />

        <vs-input 
          class="w-full mt-4" 
          label="Alt image" 
          :value="form.alt_image"
          @input="updateForm({key: 'alt_image', value: $event})"/>
        <span class="text-danger text-sm" 
          v-show="validation.alt_image?true:false">
          {{ validation.alt_image?validation.alt_image[0]:''}}
        </span>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
// Import FilePond
import vueFilePond from 'vue-filepond';

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
const FilePond = vueFilePond( FilePondPluginFileValidateType, FilePondPluginImagePreview );

export default {
  components: {
    FilePond
  },
  computed: {
    ...mapGetters({
        form: 'package_activity/getFormObj',
        validation: 'validation/getValidationErrors',
        image_preview: 'package_activity/getImagePreview'
    })
  },
  methods: {
    ...mapActions('package_activity', [
      'updateForm'
    ]),
    handleFileAdd(image_name, files) {
      if( files.file instanceof File ) {
        this.updateForm({key: 'images', value: files.file})
      }
      
    },
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
