<template>
  <div>
    <div class="mb-4">
      <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
      <span class="leading-none font-medium">Package Activity</span>
   </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Name" 
          :danger="validation.name?true:false"
          :danger-text="validation.name?validation.name[0]:''"
          :value="form.name"
          @input="updateForm({key: 'name', value: $event})" />

          <vs-input 
          class="w-full mt-4" 
          label="Slug" 
          :danger="validation.slug?true:false"
          :danger-text="validation.slug?validation.slug[0]:''"
          :value="form.slug"
          @input="updateForm({key: 'slug', value: $event})" />
         
        <vs-checkbox 
          class="w-full mt-4" 
          :danger="validation.status?true:false"
          :danger-text="validation.status?validation.status[0]:''"
          :value="form.status"
          @input="updateForm({key: 'status', value: $event})">
          Status 
        </vs-checkbox>

        <vs-textarea 
          label="Description"
          class="w-full mt-4"
          :value="form.description"
          @input="updateForm({key: 'description', value: $event})"/>

      </div>

    </div>
  </div>
</template>


<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    vSelect
  },
  computed: {
    ...mapGetters({
        form: 'package_activity/getFormObj',
        validation: 'validation/getValidationErrors'
    })
  },
  methods: {
     ...mapActions('package_activity', [
      'updateForm'
    ])
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
