<template>
  <div id="page-user-list">

    <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
      <div class="vx-row mb-6">
        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
              <ActivityForm></ActivityForm>
          </div>
          <div class="vx-card p-6 mb-6">
            <SeoInfo></SeoInfo>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
              <AdditionInfo></AdditionInfo>
            </div>
        </div>
      </div>

      <div class="vx-card p-4 mb-4">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
          </div>
      </div>
    </form>


	</div>
</template>

<script>
import vSelect from 'vue-select'
import ActivityForm from './common/ActivityForm';
import {PACKAGE_ACTIVITY} from '../../../constant/entity-identifier'
import AdditionInfo from './common/AdditionInfo';
import SeoInfo from './common/SeoInfo';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'
import {storeImage, updateImage} from '../../../services/image';

export default {
  components: {
    vSelect,
    ActivityForm,
    AdditionInfo,
    SeoInfo
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'package_activity/getFormObj',
        imageObj: 'package_activity/getImage',
        images: 'package_activity/getImages',
    }),
    packageActivityId() {
        return this.$route.params.packageActivityId;
    },
  },
  created() {
    this.clearValidationErrors();
    this.openLoading();
    this.fetchAndSetPackageActivityById(this.packageActivityId).then((res) => this.closeLoading())
      .catch(() => this.closeLoading());
  },
  methods: {
    ...mapActions({
        clearValidationErrors: 'validation/clearValidationErrors',
        updatePackageActivityAction: 'package_activity/updatePackageActivityAction',
        fetchAndSetPackageActivityById: 'package_activity/fetchAndSetPackageActivityById',
    }),
    prepareForm(data, method) {
      let formData = new FormData();
      formData.append("entity_id", data.id);
      formData.append("_method", method);
      formData.append("entity", PACKAGE_ACTIVITY);
      for (let i = 0; i < this.images.length; i++) {
          formData.append("images[]", this.images[i]);
          formData.append("alt[" + i + "]", this.form.alt_image);
      }
      return formData;
    },
    submitForm() {
      this.openLoading();
      this.updatePackageActivityAction({
        packageActivityId: this.packageActivityId,
        data: this.form
      }).then(async response => {
           if(response.status === 202) {
             if(this.images.length > 0) {
                if(response.data.data.images){
                    await updateImage(response.data.data.images[0].id, this.prepareForm(response.data.data, 'POST'));
                }else{
                  await storeImage(this.prepareForm(response.data.data, 'POST'));
                }
                this.closeLoading();
             }
            this.closeLoading();
            this.$vs.notify({ 
                text: 'Package activity updated successfully.',
                title: 'Package Activity Updated',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push('/package-activities');
           }
        }).catch(err => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
